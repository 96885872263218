// @ts-nocheck
import React from 'react';
import { Page, pdfjs } from 'react-pdf';
// import * as pdfjs from 'pdfjs-dist';
// import pdfjs from 'pdfjs-dist-show-signature';
// import PDF from 'react-pdf-js';
// import PDF from 'react-pdf-js-enable-signature';
// import md5 from 'md5';
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, Modal, Button } from 'react-bootstrap';
import MuiButton from "@mui/material/Button";
import Service from '../../Service';
import PDFBGIcon from '../Assets/img/icons/file_pdf.svg';
import ClipIcon from '../Assets/img/icons/clip_icon.svg';
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { jsPDF } from "jspdf";
import {
	imprint as IconImporint,
	imprint_check as IconImporintCheck,
	imprint_text as IconImporintText
} from '../Assets/SVGIcons';
import PDFViewer from '../Fragments/PDFViewer';
import Loading from '../Loading';

import { hookstate, StateFragment, useHookstate } from '@hookstate/core';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { LoadingButton } from '@mui/lab';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import getFileHash, { processedFiles } from '../../Utils/compress-pdf';
import FileDragDrop from "../Components/FileDragDrop/FileDragDrop";
import DocumentWithObserver from './DocumentWithObserver';
import DrivePreviewDialog from '../../drive-feature/components/drive-dialogs/drive-preview-dialog';
import { CircularProgress, Drawer } from '@mui/material';
import { showPreviewAttachment } from '.';
import { generatDownloadPresignedUrl, generatePresignedUrlsForPreview } from '../../drive-feature/data-access/api';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `//mozilla.github.io/pdf.js/build/pdf.worker.js`;
// pdfjs.cMapUrl = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`;
// console.log(pdfjs.version);
// var count = 0;
const currentRangePage = hookstate('1');
export default class DocumentPrepare {

	static get key() {
		return "document-prepare";
	}
	static get name() {
		return "DocumentPrepare";
	}
	static get code() {
		return DocumentPrepare.name.toLowerCase();
	}

	constructor(objMain) {
		this.objMain = objMain;
		this.key = DocumentPrepare.key;
		this.name = DocumentPrepare.name;
		this.code = DocumentPrepare.code;
		this.formData = {
			signers: "common:documents.host-setting.signers",
			guests: "common:documents.guest-setting.guests",
			viewers: "common:documents.viewer-setting.viewers",
			display_imprint: "common:documents.document-info.display-imprint",
			imprints: "common:documents.document-info.imprints",
		};
		this.initialValues = {
			signers: {},
			guests: {},
			viewers: {},
			display_imprint: true,
			imprints: {},
		};
		this.tempFilesSort = [];
		// console.log(pdfjs.version);
		this.objMain.setPageObject(this);
		// let fileName = "/compressed.tracemonkey.pdf";
		// this.objMain.updateState({ fileName: fileName });
		// this.selectedFile = null;
		// this.renderPDFPages(this.fileName);
		// this.Test = <Document file={this.fileName} onLoadSuccess={this.onDocumentLoadSuccess} />;
		// this.objMain.updateState({ pageNumber: 1 });
		// this.objMain.updateState({ PDFFiles: {} });
		this.currImprintProps = {};
		this.assignedUsers = [];
		this.unAssignedUsers = [];
		for (let i in this.formData.signers) {
			this.unAssignedUsers.push(i);
		}
		for (let i in this.formData.guests) {
			this.unAssignedUsers.push(i);
		}
		// this.incorrectPassword = null;
		this.pdfDocOptions = {
			cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
			cMapPacked: true,
		};
		this.dragItem = React.createRef();
		this.dragOverItem = React.createRef();
	}

	formSubmitCallback = (ev) => {
		// this.formData.term_condition = "Accepted";
		// this.objMain.setFormData(this.formData);
		// this.objMain.setActivePage(UserForm);
	};

	pageSubmit = (ev) => {
		// console.log(ev);
		let { pageState } = this.objMain.state;
		pageState[this.name].completed = true;
		this.objMain.updateState({ pageState: pageState });
		this.objMain.nextPageStep(ev, this.constructor);
	}

	allowDrop = (ev) => {
		ev.preventDefault();
	}

	onDocumentLoadSuccess = async (pdfInfo) => {
		let { numPages } = pdfInfo;
		let { PDFFiles, selectedFile, keepPDFPassword, imprints, creationType } = this.objMain.state;
		// console.log(pdfInfo);
		// console.log(tempPDFPassword, keepPDFPassword);

		let totalImprints = [];
		let usedImprints = imprints[selectedFile]
			? Object.values(imprints[selectedFile]).map(item => item.userID.toString())
			: [];

		for (let i in this.formData.signers) {
			totalImprints.push(i);
		}

		if (["signer", "circle"].includes(creationType)) {
			for (let i in this.formData.guests) {
				totalImprints.push(i);
			}
		}

		for (let i = 0; i < totalImprints.length; i++) {
			let impContainer = document.querySelector('.imprint-container');
			let findImp = impContainer?.querySelector(`.imprint-block[data-userid="${totalImprints[i]}"]`)
			if(findImp) {
				if (usedImprints.includes(totalImprints[i])) {
					findImp.classList.add('hide');
				} else {
					findImp.classList.remove('hide');
				}
			}
		}

		if (!PDFFiles[selectedFile].initialDone) {

			PDFFiles[selectedFile].numPages = numPages;
			PDFFiles[selectedFile].currentPage = PDFFiles[selectedFile].currentPage || 1;
			let firstPageInfo = await pdfInfo.getPage(1)

			// .then(resp => {
			// console.log(resp);
			// console.log(resp.view);
			PDFFiles[selectedFile].firstPageView = {
				top: firstPageInfo.view[0],
				left: firstPageInfo.view[1],
				width: firstPageInfo.view[2],
				height: firstPageInfo.view[3],
			};
			// console.log(PDFFiles);
			// });
			let pageSpace = 20;
			let docHeight = 0;
			let pages = {};
			for (let i = 1; i <= numPages; i++) {
				// console.log(i);
				let pageInfo = await pdfInfo.getPage(i);
				// .then(resp=>{
				// 	console.log(resp);
				// 	console.log(resp.view);
				// });
				pages[i] = {
					view: {
						top: pageInfo.view[0],
						left: pageInfo.view[1],
						width: pageInfo.view[2],
						height: pageInfo.view[3],
					},
				};
				docHeight += pageInfo.view[3] + pageSpace;
				// pages.push(<Page key={i} pageNumber={i + 1} renderTextLayer={false} scale={previewScale / 100} onDragOver={this.allowDrop} className={"pdf-custom-page scale-" + previewScale} />);
			}
			docHeight += pageSpace;
			let currentHeight = pageSpace;
			for (let i in pages) {
				let view = pages[i].view;
				pages[i].view.top = currentHeight;
				pages[i].view.percentage = {
					top: currentHeight / docHeight * 100,
					height: view.height / docHeight * 100,
				};
				// console.log(i, pages[i].view.percentage);
				currentHeight += view.height + pageSpace
			}
			pages[1].view.top = 0;
			pages[1].view.percentage.top = 0;
			// console.log(docHeight);

			PDFFiles[selectedFile].pages = pages;
			PDFFiles[selectedFile].initialDone = true;

			if (keepPDFPassword) {
				PDFFiles[selectedFile].password = keepPDFPassword;
			}
			// console.log(pages);
			// console.log(firstPageInfo);
			this.objMain.updateState({ keepPDFPassword: null });
			this.objMain.updateState({ PDFFiles });
		}
		this.objMain.updateState({ PDFLoadSuccess: true, pagesRendered: 0});
		// console.log(PDFFiles);
		// this.objMain.updateState({ selectedFile: fingerprint });
		// this.selectedFile = fingerprint;
		// setNumPages(numPages);
	}

	pdfPreviewScrollHandler = (ev) => {
		let { PDFFiles, selectedFile } = this.objMain.state;
		let PDFFile = PDFFiles[selectedFile];

		// console.log(PDFFile);
		let pageContainer = ev.target.querySelector(".react-pdf__Document");
		// let percentScroll = ev.target.scrollTop / (pageContainer.offsetHeight - ev.target.offsetHeight);
		// console.log(percentScroll);
		let currentPage = null;
		for (let i in PDFFile.pages) {
			let page = PDFFile.pages[i];
			let realTop = page.view.percentage.top / 100 * pageContainer.offsetHeight;
			if ((realTop - (ev.target.offsetHeight / 2)) > ev.target.scrollTop) {
				break;
			}
			currentPage = page;
			currentPage.pageNumber = i;
			PDFFiles[selectedFile].currentPage = parseInt(i);
			this.objMain.updateState({ PDFFiles });
		}
		// var wanted = Object.values(PDFFile.pages).filter((item) => {
		// 	let result = [
		// 		item.view.top >= ev.target.scrollTop
		// 	];
		// 	return !result.includes(false);
		// });
		// console.log(ev.target.scrollTop);
	}

	generateFoundOcr = (foundOcrs) => {
		if (!foundOcrs || foundOcrs.length === 0) {
			return "";
		}

		let { previewScale } = this.objMain.state;
		let foundOcrElements = [];
		for (let key in foundOcrs) {
			let foundOcr = foundOcrs[key];
			let ImprintIcon = IconImporint;
			if (foundOcr.type === "text") {
				ImprintIcon = IconImporintText;
			} else if (foundOcr.type === "check") {
				ImprintIcon = IconImporintCheck;
			}
			foundOcr.className = `imprint-block type-${foundOcr.type} group-${foundOcr.group}`;
			foundOcr.style = {
				width: (foundOcr.defaultWidth * previewScale / 100) + "px",
				height: (foundOcr.defaultHeight * previewScale / 100) + "px",
				left: (foundOcr.left * previewScale / 100) + "px",
				top: (foundOcr.top * previewScale / 100) + "px",
				fontSize: (previewScale * 11 / 100) + "px", // Scale / 100 * fontSize
			}
			foundOcr.Icon = ImprintIcon;
			if (foundOcr.group === "signers") {
				foundOcr.user = this.formData.signers[foundOcr.userID];
			} else if (foundOcr.group === "guests") {
				foundOcr.user = this.formData.guests[foundOcr.userID];
			}
			foundOcrElements.push(this.generateFoundOcrHTML(foundOcr));
		}
		return foundOcrElements;
	}


	generateFoundOcrHTML = (foundOcr) => {
		let labelText = null;
		let userID = null;
		if (foundOcr.user) {
			userID = foundOcr.user.id;
			labelText = foundOcr.user.name;
		}

		return (
			<div key={foundOcr.id}
				id={foundOcr.id}
				data-id={foundOcr.id}
				data-user-i-d={userID}
				data-type={foundOcr.type}
				data-group={foundOcr.group}
				data-width={foundOcr.defaultWidth}
				data-height={foundOcr.defaultHeight}
				onDragStart={this.handleDragStart}
				onMouseDown={this.imprintHandleMouseDown}
				title={labelText}
				className={foundOcr.className}
				style={foundOcr.style}
			>
				<foundOcr.Icon className={foundOcr.group + "-" + foundOcr.type} />
				<span className="label-text">{labelText}</span>
			</div>
		)
	}

	generateImprints = (imprints) => {
		if (!imprints || imprints.length === 0) {
			return "";
		}

		let { previewScale } = this.objMain.state;
		let imprintElements = [];
		for (let key in imprints) {
			let imprint = imprints[key];
			let ImprintIcon = IconImporint;
			if (imprint.type === "text") {
				ImprintIcon = IconImporintText;
			} else if (imprint.type === "check") {
				ImprintIcon = IconImporintCheck;
			}
			imprint.className = `imprint-block type-${imprint.type} group-${imprint.group}`;
			imprint.style = {
				width: (imprint.defaultWidth * previewScale / 100) + "px",
				height: (imprint.defaultHeight * previewScale / 100) + "px",
				left: (imprint.left * previewScale / 100) + "px",
				top: (imprint.top * previewScale / 100) + "px",
				fontSize: (previewScale * 11 / 100) + "px", // Scale / 100 * fontSize
			}
			imprint.Icon = ImprintIcon;
			if (imprint.group === "signers") {
				imprint.user = this.formData.signers[imprint.userID];
			} else if (imprint.group === "guests") {
				imprint.user = this.formData.guests[imprint.userID];
			}
			imprintElements.push(this.generateHTMLImprint(imprint));
		}
		return imprintElements;
	}
	onRenderSuccess = ()=>{

		this.objMain.setState(prevState => ({
			pagesRendered: prevState.pagesRendered + 1,
		}));
	}
	generatePDFPages = () => {
		let { PDFFiles, selectedFile, previewScale, imprints, formData, pagesRendered } = this.objMain.state;
		let numPages = PDFFiles[selectedFile].numPages;
		let pagesImprints = {};
		let imprintsInFile = imprints[selectedFile];

		if (imprintsInFile) {
			for (let imprint of Object.values(imprintsInFile)) {
				if (!pagesImprints.hasOwnProperty(imprint.page)) {
					pagesImprints[imprint.page] = {}
				}
				if (Object.keys(formData.signers).includes(`${imprint.userID}`) || Object.keys(formData.guests).includes(`${imprint.userID}`)){
					// console.log('imprint', imprint);
					pagesImprints[imprint.page][imprint.id] = imprint;
				}
			}
		}
		// let pages = [];

		const pagesRenderedPlusOne = Math.min(pagesRendered + 1, numPages || 0);
		// console.log(pagesRenderedPlusOne)
		let pages = Array.from(
			new Array(pagesRenderedPlusOne < 0 ? 0 : pagesRenderedPlusOne),
			(el, i) => {
			  const isCurrentlyRendering = pagesRenderedPlusOne === i + 1;
			  const isLastPage = numPages === i + 1;
			  const needsCallbackToRenderNextPage = isCurrentlyRendering && !isLastPage;
			  let Imprints = this.generateImprints(pagesImprints[i + 1]);
				return (
					<div key={i} className="pdf-page-container">
						<Page pageNumber={i + 1}  onRenderSuccess={
                          needsCallbackToRenderNextPage ? this.onRenderSuccess : null
                        } renderTextLayer={false} scale={previewScale / 100} onDragOver={this.allowDrop} className={"pdf-custom-page scale-" + previewScale}>
							{Imprints}
						</Page >
					</div>
				);
			},
		  );
		// for (let i = 0; i < numPages; i++) {
		// 	let Imprints = this.generateImprints(pagesImprints[i + 1]);
		// 	pages.push(
		// 		<div key={i} className="pdf-page-container">
		// 			<Page pageNumber={i + 1} renderTextLayer={false} scale={previewScale / 100} onDragOver={this.allowDrop} className={"pdf-custom-page scale-" + previewScale}>
		// 				{Imprints}
		// 			</Page >
		// 		</div>
		// 	);
		// }
		return pages;
	}

	preparePdfFiles = async (inputFiles) => {
        try {
            let files = [];
            let promises = [];
            for (let file of inputFiles) {
                const allowedImageTypes = ['image/png', 'image/jpeg', 'image/gif', 'image/jpg'];
                if (allowedImageTypes.includes(file.type)) {
                    const formDataFile = new FormData();
                        formDataFile.append('file', file, file.name);
                        const promise = Service.convertImageToPdf(formDataFile).then(async response => {
                            const fileName = file.name.slice(0, file.name.lastIndexOf(".")) + ".pdf"
                            // return new File([response], fileName, {type: 'application/pdf'});
                            const url = response?.payload?.url;
                            // convert url to pdf file blob
                            const responseBlob = await Service.getDocumentBlob(url);
                            return new File([responseBlob], fileName, { type: 'application/pdf' });
                        });
                    promises.push(promise);
                } else {
                    if (file.type.includes('pdf')) {
                        files.push(file);
                    }
                }
            }

            const responses = await Promise.all(promises);
            return [...files, ...responses];
        } catch (error) {
            console.error("Prepare files error:", error);
            return [];
        }
    }

	dateToString = (value) => {
		// console.log(value);
		try {
			value = new Date(value);
			return value.toISOString();
		} catch (ex) {
			return value.toString();
		}
	}


	uploadAttachment = async (inputFiles) => {
		const files = inputFiles.target.files;
		let { AttachmentFiles, formData } = this.objMain.state;
		if(AttachmentFiles == undefined){
			AttachmentFiles = {}
		}
		formData.attachments = {};
		for (let file of files) {
			getFileHash(file).then(async (key) => {
				console.log(key);
				let fileKey = key;
				AttachmentFiles[fileKey] = {}
				AttachmentFiles[fileKey] = {
					hash: fileKey,
					file: file,
					name: file.name,
					type: file.type,
					size: file.size,
					lastModified: file.lastModified,
					lastModifiedDate: file.lastModifiedDate,
					// pages: [],
				};
				this.objMain.updateState({ AttachmentFiles });

				let oFile = AttachmentFiles[fileKey];

				let newFile = {
					hash: fileKey,
					name: oFile.name,
					// temp: tempInfo.temp,
					// url: tempInfo.url,
					// bucket: tempInfo.bucket,
					size: oFile.size,
					type: oFile.type,
					lastModified: this.dateToString(oFile.lastModified),
					lastModifiedDate: this.dateToString(oFile.lastModifiedDate),
				};
				if (!oFile.url) {
					AttachmentFiles[fileKey].uploadDone = false;
					this.objMain.setState({
						AttachmentFiles
					});
					let tempInfo = await Service.FileUpload(oFile.file).catch(err => {
						//console.log(err.response);
					});
					if (!tempInfo) {
						return;
					}
					newFile.temp = tempInfo.temp;
					// console.log(tempInfo);
					AttachmentFiles[fileKey].temp = newFile.temp;
					AttachmentFiles[fileKey].uploadDone = true;
					this.objMain.setState({
						AttachmentFiles
					});
				}

				if (oFile.hasOwnProperty("password")) {
					newFile.password = oFile.password;
				}
				formData.attachments[key] = newFile;
				this.objMain.updateState({ formData });

			})
		}
	}

	registerFiles = (files) => {
		console.log("==> DocumentPrepare", {files});
		let lengthFiles = files.length
		if(files.length > 1){
			this.objMain.setState({
				isFileLoading: true
			})
		}
		let defaultObjectLength = Object.keys(this.objMain.state.PDFFiles)?.length || 0;
		// console.log(defaultObjectLength);
		for (let file of files) {
			getFileHash(file).then(key=>{
				let fileKey = key;
				// console.log('2ur2132l', fileKey)
				let { PDFFiles, filesSort, isFileLoading, creationType, imprints } = this.objMain.state;
				let initialFilesSort = [...new Set([...this.tempFilesSort, ...filesSort])];
				this.tempFilesSort = [...initialFilesSort.filter(item => item !== fileKey), fileKey];
				// let url = URL.createObjectURL(file);
				// if(file.size / (1024*1024) > 200){
				// 	isFileLoading = true;
				// 	onProcessInputPDF(file, fileKey, this.objMain);
				// } else {
				// 	isFileLoading = false;
				// }
				PDFFiles[fileKey] = {
					hash: fileKey,
					file: file,
					name: file.name,
					type: file.type,
					size: file.size,
					lastModified: file.lastModified,
					lastModifiedDate: file.lastModifiedDate,
					// pages: [],
				};

				if(defaultObjectLength + lengthFiles === Object.keys(PDFFiles)?.length){
					this.objMain.setState({
						isFileLoading: false
					})
				}
				// console.log(PDFFiles[fileKey])
				this.objMain.updateState({ PDFFiles, showLoadingPDFLoad: false });
				this.objMain.updateState({ filesSort: this.tempFilesSort });
				this.objMain.updateState({ selectedFile: fileKey }, () => {
					this.handleHideAllImprints();
				});
				// console.log(this.objMain.state.PDFFiles);
			});
			// reader.onload = async (ev) => {
			// 	console.log('ur2132l', await getFileHash(file))
			// 	// console.log(ev);
			// 	// let blob = new Blob([reader.result], { type: file.type });

			// };
			// reader.onerror = () => {
			// 	//(reader.error);
			// };
			// reader2.onload = (ev) => {

			// }
			// reader2.readAsDataURL(file);
			// reader.readAsBinaryString(file);
		}
	}

	renderPDFPages = async (ev) => {
		let files = [];
		if(ev.target.files){
			const allowedImageTypes = ['image/png', 'image/jpeg', 'image/gif', 'image/jpg'];

			const isFileAllowed = Array.from(ev.target.files).some(file =>
				allowedImageTypes.includes(file.type) || file.type.includes('pdf')
			);

			if (isFileAllowed) {
				this.objMain.setState({
					showLoadingPDFLoad: true
				}, async () => {
					files = await this.preparePdfFiles(ev.target.files);
				});
			}else{
				files = await this.preparePdfFiles(ev.target.files);
			}
		}
		if(files.length > 0){
			this.registerFiles(files);
		} else {
			this.objMain.setState({
				showLoadingPDFLoad: false
			});
		}
		ev.target.value = "";
		// console.log(ev);
		// await registerFiles(ev.target.files);
		// return;
		// let reader = new FileReader();
		// reader.readAsBinaryString(file);

		// reader.onload = () => {
		// 	// console.log(reader.result);
		// 	// let blob = new Blob([reader.result], { type: file.type });
		// 	// console.log(blob);
		// 	let fileKey = md5(reader.result);
		// 	console.log(fileKey);
		// 	let { PDFFiles } = this.objMain.state;
		// 	PDFFiles[fileKey] = {
		// 		file: file,
		// 		// pages: [],
		// 	};
		// 	this.objMain.updateState({ PDFFiles });
		// 	this.objMain.updateState({ selectedFile: fileKey });
		// 	console.log(this.objMain.state.PDFFiles);
		// };

		// reader.onerror = () => {
		// 	console.log(reader.error);
		// };
	}

	handleDropFile = async (ev) => {
		ev.preventDefault();
		// ev.stopPropagation();
		// console.log(ev);
		// this.isDragLeave = false;
		// this.isDragOver = false;
		// e.target.style.borderColor = "";
		// const { onDrop } = this.props;
		// let files = e.dataTransfer.files;
		// console.log(ev.dataTransfer.files);
		let files = [];
		if(ev.dataTransfer.files){
			const allowedImageTypes = ['image/png', 'image/jpeg', 'image/gif', 'image/jpg'];

			const isFileAllowed = Array.from(ev.dataTransfer.files).some(file =>
				allowedImageTypes.includes(file.type) || file.type.includes('pdf')
			);

			if (isFileAllowed) {
				this.objMain.setState({
					showLoadingPDFLoad: true
				});
			}
			files = await this.preparePdfFiles(ev.dataTransfer.files);
		}
		if(files.length > 0){
			this.registerFiles(files);
		} else {
			this.objMain.setState({
				showLoadingPDFLoad: false
			});
		}
		// this.registerFiles(ev.dataTransfer.files);
		// if (!files.length) return;
		// const { validateFiles } = this.props;
		// if (validateFiles) this.setState({ error: validateFiles(files) });
		// if (onDrop) {
		//     onDrop(files);
		// }
	};

	handleDragOver = (ev) => {
		ev.preventDefault();
		// ev.stopPropagation();
		// console.log(ev);
		// if (!this.isDragOver) {
		//     this.isDragLeave = false;
		//     this.isDragOver = true;
		//     e.target.style.borderColor = "#40a9ff";
		// }
	};

	handleDragLeave = (ev) => {
		ev.preventDefault();
		// console.log(ev);
		// if (!this.isDragLeave) {
		//     this.isDragOver = false;
		//     this.isDragLeave = true;
		//     e.target.style.borderColor = "";
		// }
	};

	imprintControllerHandleMouseDown = (ev) => {
		if (ev.target.classList.contains("imprint-stamp")) {
			// console.log(ev.target);
			let targetBlock = ev.target;
			let shiftX = ev.clientX - ev.target.getBoundingClientRect().left;
			let shiftY = ev.clientY - ev.target.getBoundingClientRect().top;

			function handleMouseMove(event) {
				document.body.style.userSelect = "none";
				let containerBlock = targetBlock.parentNode;
				let marginLeft = event.pageX - shiftX - containerBlock.getBoundingClientRect().left;
				let marginTop = event.pageY - shiftY - containerBlock.getBoundingClientRect().top;
				let parentWidth = containerBlock.offsetWidth;
				let parentHeight = containerBlock.offsetHeight;

				if (marginLeft < 0) {
					marginLeft = 0;
				} else if (marginLeft > parentWidth - targetBlock.offsetWidth) {
					marginLeft = parentWidth - targetBlock.offsetWidth;
				}
				if (marginTop < 0) {
					marginTop = 0;
				} else if (marginTop > parentHeight - targetBlock.offsetHeight) {
					marginTop = parentHeight - targetBlock.offsetHeight;
				}

				targetBlock.style.left = marginLeft + 'px';
				targetBlock.style.top = marginTop + 'px';
			}

			handleMouseMove(ev);

			// (2) move the ball on mousemove
			document.onmousemove = handleMouseMove;

			// (3) drop the ball, remove unneeded handlers
			document.onmouseup = function (event) {
				document.onmousemove = null;
				document.onmouseup = null;
				document.body.style.userSelect = null;
			};
		}
	};

	setDragedObjectMargin = (parentNode) => {
		parentNode.append(this.currImprintProps.targetBlock);
		let marginLeft = this.currImprintProps.pageX - this.currImprintProps.shiftX - parentNode.getBoundingClientRect().left;
		let marginTop = this.currImprintProps.pageY - this.currImprintProps.shiftY - parentNode.getBoundingClientRect().top;
		if (this.currImprintProps.fromOriginal) {
			marginLeft = this.currImprintProps.pageX - this.currImprintProps.subShiftX - parentNode.getBoundingClientRect().left;
			marginTop = this.currImprintProps.pageY - this.currImprintProps.subShiftY - parentNode.getBoundingClientRect().top;
		}

		let parentWidth = this.currImprintProps.targetBlock.parentNode.offsetWidth;
		let parentHeight = this.currImprintProps.targetBlock.parentNode.offsetHeight;

		if (marginLeft < 0) {
			marginLeft = 0;
		} else if (marginLeft > parentWidth - this.currImprintProps.targetBlock.offsetWidth) {
			marginLeft = parentWidth - this.currImprintProps.targetBlock.offsetWidth;
		}
		if (marginTop < 0) {
			marginTop = 0;
		} else if (marginTop > parentHeight - this.currImprintProps.targetBlock.offsetHeight) {
			marginTop = parentHeight - this.currImprintProps.targetBlock.offsetHeight;
		}

		this.currImprintProps.targetBlock.style.left = marginLeft + 'px';
		this.currImprintProps.targetBlock.style.top = marginTop + 'px';
	}

	imprintHandleMouseDown = (ev) => {
		// let imprintBlock = document.querySelector(".imprint-stamp");
		var { previewScale } = this.objMain.state;
		// let containerBlock = document.querySelector(".full-preview .control-preview");
		let originalBlock = ev.target.closest(".imprint-block");
		let pageContainer = originalBlock.closest(".pdf-custom-page");
		let targetBlock = originalBlock.cloneNode(true);
		// console.log(pageContainer);
		if (!originalBlock.classList.contains("original")) {
			originalBlock.classList.add("hide");
		} else if (originalBlock.dataset.type === "signature") {
			originalBlock.classList.add("hide");
		}
		this.currImprintProps.originalBlock = originalBlock;
		if (pageContainer) {
			pageContainer.append(originalBlock);
			pageContainer.append(targetBlock);
		}
		// console.log(originalBlock.dataset.type);




		// targetBlock.addEventListener("mousedown", this.imprintHandleMouseDown);

		this.currImprintProps.subShiftX = 75;
		this.currImprintProps.subShiftY = 20;
		this.currImprintProps.fromOriginal = false;
		this.currImprintProps.originalContainer = null;
		if (targetBlock.classList.contains("original")) {
			this.currImprintProps.originalContainer = targetBlock.closest(".imprint-container");
			// targetBlock = targetBlock.cloneNode(true);
			targetBlock.classList.remove("original");
			// targetBlock.addEventListener("dragstart", this.imprintHandleDragStart);
			// targetBlock.dataset.id = (new Date()).toISOString();
			// targetBlock.dataset.id = targetBlock.dataset.id;
			// containerBlock.append(targetBlock);
			this.currImprintProps.fromOriginal = true;
			// targetBlock.style.minWidth = parseInt(targetBlock.getAttribute("defwidth")) * (previewScale / 100) + "px";
			// targetBlock.style.minHeight = parseInt(targetBlock.getAttribute("defHeight")) * (previewScale / 100) + "px";
			// shiftX = parseInt(targetBlock.offsetWidth / 2);
			// shiftY = parseInt(targetBlock.offsetHeight / 2);
			// console.log(targetBlock.offsetWidth, targetBlock.offsetHeight, shiftX, shiftY);
		} else {
			// targetBlock.style.borderWidth = "3px";
		}

		// console.log(targetBlock.style.left);
		// console.log(targetBlock.style.top);
		// console.log(targetBlock.style.minHeight);
		// console.log(targetBlock.style.minWidth);
		// console.log(targetBlock.getBoundingClientRect().left);
		// console.log(targetBlock.getBoundingClientRect().top);
		// console.log(originalBlock.getBoundingClientRect().left);
		// console.log(originalBlock.getBoundingClientRect().top);

		this.currImprintProps.shiftX = ev.clientX - targetBlock.getBoundingClientRect().left;
		this.currImprintProps.shiftY = ev.clientY - targetBlock.getBoundingClientRect().top;
		this.currImprintProps.subShiftX = parseInt(targetBlock.dataset.width) * (previewScale / 100) / 2;
		this.currImprintProps.subShiftY = parseInt(targetBlock.dataset.height) * (previewScale / 100) / 2;

		if (!targetBlock.dataset.id) {
			targetBlock.dataset.id = (new Date()).toISOString();
			// targetBlock.dataset.id = targetBlock.dataset.id;
		}
		this.currImprintProps.targetBlock = targetBlock;

		// imprintBlock.style.opacity = "0.5";
		// imprintBlock.style.pointerEvents = "none";

		const handleMouseMove = (event) => {
			event.stopPropagation();
			this.currImprintProps.pageX = event.pageX;
			this.currImprintProps.pageY = event.pageY;
			document.body.style.userSelect = "none";
			let containerBlock = document.querySelector(".full-preview .control-preview");
			// let marginLeft = this.currImprintProps.pageX + containerBlock.scrollLeft - this.currImprintProps.shiftX - containerBlock.getBoundingClientRect().left;
			// let marginTop = this.currImprintProps.pageY + containerBlock.scrollTop - this.currImprintProps.shiftY - containerBlock.getBoundingClientRect().top;
			let pageContainer = targetBlock.closest(".pdf-custom-page");
			let pageBlock = null;
			let imprintControlBlock = null;

			// containerBlock.append(targetBlock);
			targetBlock.classList.add("not-allowed");
			targetBlock.classList.remove("ondrag");
			targetBlock.style.width = parseInt(targetBlock.dataset.width) * (previewScale / 100) + "px";
			targetBlock.style.height = parseInt(targetBlock.dataset.height) * (previewScale / 100) + "px";
			// targetBlock.style.position = "absolute";
			targetBlock.hidden = true;
			let elemBelow = document.elementFromPoint(event.clientX, event.clientY);
			targetBlock.hidden = false;

			if (elemBelow) {
				pageBlock = elemBelow.closest('.pdf-custom-page');
				imprintControlBlock = elemBelow.closest('.imprint-stamp');
			}

			targetBlock.style.borderWidth = "3px";
			if (imprintControlBlock) {
				// console.log(targetBlock.classList);
				targetBlock.classList.add("original");
				imprintControlBlock = imprintControlBlock.querySelector('.imprint-container');
				targetBlock.style.width = null;
				targetBlock.style.height = null;
				this.setDragedObjectMargin(imprintControlBlock);
				targetBlock.classList.remove("not-allowed");
				targetBlock.classList.add("ondrag");
				targetBlock.style.borderWidth = "1px";
			} else if (pageBlock) {
				this.setDragedObjectMargin(pageBlock);
				targetBlock.classList.remove("not-allowed");
			} else if (pageContainer) {
				this.setDragedObjectMargin(pageContainer);
			} else {
				this.setDragedObjectMargin(containerBlock);
			}
		}

		handleMouseMove(ev);

		// (2) move the ball on mousemove
		document.onmousemove = handleMouseMove;

		// (3) drop the ball, remove unneeded handlers
		document.onmouseup = this.handleMouseUp;
	}

	handleMouseUp = (event) => {
		var { selectedFile, previewScale, imprints } = this.objMain.state;
		let targetBlock = this.currImprintProps.targetBlock;
		document.onmousemove = null;
		targetBlock.hidden = true;
		let elemBelow = document.elementFromPoint(event.clientX, event.clientY);
		targetBlock.hidden = false;
		let pageContainer = targetBlock.closest(".pdf-custom-page");
		let pageBlock = null;
		let imprintControlBlock = null;
		if (elemBelow) {
			pageBlock = elemBelow.closest('.pdf-custom-page');
			imprintControlBlock = elemBelow.closest('.imprint-stamp');
		}
		document.onmouseup = null;
		// imprintBlock.style.opacity = null;
		// imprintBlock.style.pointerEvents = null;
		targetBlock.classList.remove("not-allowed");
		document.body.style.userSelect = null;

		if ((!pageBlock && !pageContainer) || imprintControlBlock) {
			// targetBlock.remove();

			// targetBlock.classList.add("original");
			this.currImprintProps.originalBlock.classList.remove("ondrag");
			this.currImprintProps.originalBlock.classList.remove("hide");
			// imprintControlBlock.append(targetBlock);
			// console.log(targetBlock);
			if (imprints.hasOwnProperty(selectedFile)) {
				let imprint = imprints[selectedFile];
				if (imprint.hasOwnProperty(targetBlock.dataset.id)) {
					delete imprint[targetBlock.dataset.id];
					let impContainer = document.querySelector('.imprint-container');
					let findImp = impContainer?.querySelector(`.imprint-block[data-userid="${targetBlock.dataset.userID}"]`);
					findImp.classList.remove('hide')
					if (Object.keys(imprint).length === 0) {
						delete imprints[selectedFile];
					}
				}
			}
		} else if (pageContainer) {
			if (!imprints.hasOwnProperty(selectedFile)) {
				imprints[selectedFile] = {}
			}
			let imprint = imprints[selectedFile];
			let uniqueImprint = {
				id: targetBlock.dataset.id,
				userID: targetBlock.dataset.userID,
				userid: targetBlock.dataset.userID,
				type: targetBlock.dataset.type,
				group: targetBlock.dataset.group,
				page: pageContainer.dataset.pageNumber,
				top: targetBlock.offsetTop * 100 / previewScale,
				left: targetBlock.offsetLeft * 100 / previewScale,
				defaultWidth: targetBlock.dataset.width,
				defaultHeight: targetBlock.dataset.height,
			};
			imprint[targetBlock.dataset.id] = uniqueImprint;

		}

		// imprints = JSON.parse(JSON.stringify(imprints));
		// console.log(imprints);
		// console.log(this.objMain.state.imprints);
		this.objMain.updateState({ imprints });
		if (!this.currImprintProps.fromOriginal) {

			// 	targetBlock.remove();
			// 	// targetBlock.removeAttribute("style");
			// 	// this.currImprintProps.originalContainer.append(targetBlock);
			this.currImprintProps.originalBlock.classList.remove("hide");
		}
		targetBlock.remove();

	};

	handleDragStart = (ev) => {
		// console.log(ev);
		return false;
	}

	imprintHandleMouseUp = (ev) => {
		// console.log(ev);
	}

	increasePreviewSize = (ev) => {
		let { previewScale, PDFFiles,selectedFile } = this.objMain.state;
		previewScale += 10;
		if (previewScale > 1000) {
			previewScale = 1000;
		}
		let PDFFile = PDFFiles[selectedFile];
		this.objMain.updateState({ previewScale });
	}

	decreasePreviewSize = (ev) => {
		let { previewScale, PDFFiles,selectedFile } = this.objMain.state;

		let PDFFile = PDFFiles[selectedFile];
		previewScale -= 10;
		if (previewScale < 10) {
			previewScale = 10;
		}
		this.objMain.updateState({ previewScale });
	}

	handleHideAllImprints = () => {
		let {creationType} = this.objMain.state;

		let totalImprints = [];

		for (let i in this.formData.signers) {
			totalImprints.push(i);
		}

		if (["signer", "circle"].includes(creationType)) {
			for (let i in this.formData.guests) {
				totalImprints.push(i);
			}
		}

		for (let i = 0; i < totalImprints.length; i++) {
			let impContainer = document.querySelector('.imprint-container');
			let findImp = impContainer?.querySelector(`.imprint-block[data-userid="${totalImprints[i]}"]`)
			if(findImp) {
				findImp.classList.add('hide');
			}
		}

	}

	selectFile = (ev) => {
		let { selectedFile } = this.objMain.state;
		let fileCode = ev.target.closest(".file-item").dataset.file;
		if (fileCode !== selectedFile) {
			// let PDFFile = PDFFiles[fileCode];
			// this.objMain.updateState({ tempPDFPassword: PDFFile.password });
			this.handleHideAllImprints();
			this.objMain.updateState({ selectedFile: fileCode });
			this.objMain.updateState({ PDFLoadSuccess: false });
		}
	}

	removeFile = (ev) => {
		let { PDFFiles, selectedFile, filesSort, imprints } = this.objMain.state;
		let fileCode = ev.target.closest(".file-item").dataset.file;
		if (fileCode) {
			delete PDFFiles[fileCode];
			delete imprints[fileCode];
			let filteredFilesSort = filesSort.filter(item => item !== fileCode)
			if (fileCode === selectedFile) {
				selectedFile = null;
				if (Object.keys(PDFFiles).length > 0) {
					selectedFile = Object.keys(PDFFiles)[0];
				}
				this.objMain.updateState({ selectedFile });
			}
			// console.log(selectedFile);
			this.objMain.updateState({ PDFFiles });
			this.objMain.updateState({ filesSort: filteredFilesSort });
		}
	}

	renderFileList = () => {
		let { PDFFiles, selectedFile, filesSort } = this.objMain.state;

		return (
			<FileDragDrop
				fileOrder={filesSort}
				isDraggable={true}
				documentSection={"DocumentPrepare"}
				pdfFileObject={PDFFiles}
				onChangeObject={(keyArray) => {
					this.objMain.updateState({
						filesSort: keyArray,
					})
				}}
				selectedFile={selectedFile}
				onClickDocumentPrepare={this.selectFile}
				onRemoveDocumentPrepare={this.removeFile}
				onClickDocumentDetail={() => {}}
				FileListRemove={<></>}
			/>
		);
	}

	renderAttachmentList = () => {
		let { AttachmentFiles } = this.objMain.state;
		// console.log(this.objMain.previewAttachment)
		return (
			<>
			{
				AttachmentFiles ? Object.keys(AttachmentFiles).map((key) => {
					return (
						<div className='attachment-item' data-file={key}>
							{!AttachmentFiles[key]?.uploadDone ? <CircularProgress sx={{marginRight: '10px'}} size={20}/>: null}
							<span
								className="file-name"
								onClick={()=>{
									// console.log('clcl');
									if(!AttachmentFiles[key]?.uploadDone) return;
									this.objMain.previewAttachment(AttachmentFiles[key]);
								}}
								title={AttachmentFiles[key]?.name}
							>
								{AttachmentFiles[key]?.name}
							</span>
							<button
								type="button"
								className="btn-icon file-remove"
								onClick={this.onRemoveAttachment}
							>
								<CancelOutlinedIcon name="delete"/>
							</button>
						</div>
					)
				}): <></>
			}
			</>
		)
	}

	onRemoveAttachment = (ev) => {
		let { AttachmentFiles } = this.objMain.state;
		let fileCode = ev.target.closest(".attachment-item").dataset.file;
		if(fileCode){
			delete AttachmentFiles[fileCode];
			this.objMain.updateState({ AttachmentFiles });
		}
	}


	generateHTMLImprint = (imprint) => {
		let labelText = null;
		let userID = null;
		if (imprint.user) {
			userID = imprint.user.id;
			labelText = imprint.user.name;
		}

		return (
			<div key={imprint.id}
				id={imprint.id}
				data-id={imprint.id}
				data-user-i-d={userID}
				data-userid={userID}
				data-type={imprint.type}
				data-group={imprint.group}
				data-width={imprint.defaultWidth}
				data-height={imprint.defaultHeight}
				onDragStart={this.handleDragStart}
				onMouseDown={this.imprintHandleMouseDown}
				title={labelText}
				className={imprint.className}
				style={imprint.style}
			>
				<imprint.Icon className={imprint.group + "-" + imprint.type} />
				<span className="label-text">{labelText}</span>
			</div>
		)
	}

	renderUsersImprint = (users, group) => {
		let { imprints, creationType } = this.objMain.state;
		let sort_users = Object.values(users || {}).sort(function (a, b) {
			return a.sign_seq - b.sign_seq;
		});

		if (creationType === "sender" && group === "guests") {
			return null;
		}

		let imprintElements = [];
		let css = ["imprint-block", "type-signature", "original"];
		let className = css.join(" ");

		let assigned = [];
		let assignedTrue = [];

		for (let file in imprints) {
			for (let user in imprints[file]) {
				assigned.push(user);
				assignedTrue.push(user.split('-')?.[1]);
			}
		}
		// console.log(assigned, assignedTrue);
		let i = -1;

		for (let user of sort_users) {
			// let user = users[i];
			i += 1;
			let hide = "";
			if (assigned.includes(group + "-" + i) || assignedTrue.includes(`${user.user_id}`)) {
				hide = " hide";
			}
			imprintElements.push(this.generateHTMLImprint({
				id: group + "-" +  i,
				type: "signature",
				group: group,
				userid: user.user_id,
				defaultWidth: 60,
				defaultHeight: 60,
				className: className + " group-" + group + hide,
				style: null,
				user: user,
				Icon: IconImporint,
			}));
		}

		return imprintElements;
	}

	// setAutoImprintObject = (users, group, assigned, PDFFile) => {
	// 	for (let i in users) {
	// 		let id = "signers-" + i;
	// 		if (assigned.includes(id)) {
	// 			continue;
	// 		}
	// 		imprint[id] = {
	// 			id: id,
	// 			userID: i,
	// 			type: "signature",
	// 			group: group,
	// 			page: 1,
	// 			top: PDFFile.firstPageView.height - (imprintSize * rowUsed) - (space * rowUsed),
	// 			left: left,
	// 			defaultWidth: imprintSize,
	// 			defaultHeight: imprintSize,
	// 		};

	// 		imprintCount++;
	// 		left += imprintSize + space
	// 		if (imprintCount === imprintPerRow) {
	// 			left = space + margin;
	// 			rowUsed++;
	// 		}
	// 	}
	// }

	autoAddImprints = (ev) => {
		var { selectedFile, imprints, creationType, PDFFiles } = this.objMain.state;
		if (!imprints.hasOwnProperty(selectedFile)) {
			imprints[selectedFile] = {}
		}
		let PDFFile = PDFFiles[selectedFile];
		const curPage = +currentRangePage.get();
		let pageView = PDFFile.pages[curPage].view;

		let rowUsed = 1;
		let space = 4;
		let imprintSize = 60;
		if (pageView.width < imprintSize) {
			return;
		}

		let assigned = [];
		let assignedTrue = [];

		for (let user in imprints[selectedFile]) {
			assigned.push(user);
			assignedTrue.push(document.getElementById(user)?.dataset?.userid);
		}

		let remainUsers = [];
		for (let i in this.formData.signers) {
			let id = "signers-" + i;
			if (assigned.includes(id)) {
				continue;
			}
			remainUsers.push(id);
		}

		if (["signer", "circle"].includes(creationType)) {
			for (let i in this.formData.guests) {
				let id = "guests-" + i;
				if (assigned.includes(id)) {
					continue;
				}
				remainUsers.push(id);
			}
		}

		let pageWidth = pageView.width;
		pageWidth = pageWidth - (space * 2);
		let imprintPerRow = Math.floor((pageWidth + space) / (imprintSize + space));
		if (remainUsers.length < imprintPerRow) {
			imprintPerRow = remainUsers.length;
		}
		let allImprintRowWidth = (imprintPerRow * (imprintSize + space)) - space;
		let remainingSpace = pageWidth - allImprintRowWidth;
		let left = space;  // Start from the left of the page
		let imprintCount = 0;
		let imprint = imprints[selectedFile];

		let setAutoImprintObject = (users, group) => {
			let sort_users = Object.values(users || {}).sort((a,b)=> a.sign_seq - b.sign_seq)
			for (let i = 0; i < sort_users.length; i++) {
				let id = group + "-" + sort_users[i].user_id;
				let impContainer = document.querySelector('.imprint-container');
				let findImp = impContainer?.querySelector(`.imprint-block[data-userid="${sort_users[i].user_id}"]`);
				if (assigned.includes(id) || assignedTrue.includes(`${sort_users[i].user_id}`)) {
					continue;
				}
				if(findImp) {
					findImp.classList.add('hide');
				}
				//chỗ này có thể dùng cho ocr
				imprint[id] = {
					id: id,
					userID: sort_users[i].user_id,
					userid: sort_users[i].user_id,
					type: "signature",
					group: group,
					page: curPage,
					top: pageView.height -  (imprintSize * rowUsed) - (space * rowUsed),
					left: left,
					defaultWidth: imprintSize,
					defaultHeight: imprintSize,
				};

				imprintCount++;
				left += imprintSize + space
				if (imprintCount === imprintPerRow) {
					imprintCount = 0;
					left = space;  // Reset to left for the next row
					rowUsed++;
				}
			}
		}

		setAutoImprintObject(this.formData.signers, "signers");

		if (["signer", "circle"].includes(creationType)) {
			setAutoImprintObject(this.formData.guests, "guests");
		}

		this.objMain.updateState({ imprints });
	}

	clearAllImprint = (ev) => {

		var { selectedFile, imprints, creationType, PDFFiles } = this.objMain.state;

		let sort_users = Object.values(imprints[selectedFile] || {})
			// console.log(sort_users)
		for (let i = 0; i < sort_users.length; i++) {
			let impContainer = document.querySelector('.imprint-container');
			let findImp = impContainer?.querySelector(`.imprint-block[data-userid="${sort_users[i].userid}"]`)
			if(findImp) {
				findImp.classList.remove('hide');
			}
		}

		imprints[selectedFile] = {};

		this.objMain.updateState({ imprints });
	}

	renderImprintStampController = () => {
		let { t } = this.props;

		let SignersImprint = this.renderUsersImprint(this.formData.signers, "signers");
		let GuestsImprint = this.renderUsersImprint(this.formData.guests, "guests");
		// if ([true, "true"].includes(this.formData.display_imprint)) {
		// }
		return (
			<div className="imprint-stamp" onMouseDown={this.imprintControllerHandleMouseDown} onDragStart={this.handleDragStart}>
				<div className="imprint-container">
					{/* <div className="imprint-block type-text original" data-type="text" onMouseDown={this.imprintHandleMouseDown} onDragStart={this.handleDragStart} data-width={240} data-height={40}>
						<IconImporintText />
						<span className="label-text">text</span>
					</div>
					<div className="imprint-block type-check original" data-type="check" onMouseDown={this.imprintHandleMouseDown} onDragStart={this.handleDragStart} data-width={20} data-height={20}>
						<IconImporintCheck />
						<span className="label-text">check</span>
					</div> */}
					{SignersImprint}
					{GuestsImprint}
				</div>

				<div className="imprint-operator">
					<MuiButton variant="contained" onClick={this.autoAddImprints}>
						<span>{t("common:documents.document-prepare.auto")}</span>
					</MuiButton>
					<MuiButton variant="outlined" onClick={this.clearAllImprint}>
						<span>{t("common:documents.document-prepare.clear")}</span>
					</MuiButton>
				</div>
			</div>
		);
	}

	formSubmitValidation = (ev) => {
		ev.preventDefault();

		// if ([true, "true"].includes(this.formData.display_imprint)) {
		// 	let { t } = this.props;
		// 	let { imprints, modalShow, modalTitle, modalMessage, ModalAction } = this.objMain.state;
		// 	let signers = Object.keys(this.formData.signers).length;
		// 	signers += Object.keys(this.formData.guests).length;
		// 	let assigned = 0;
		// 	let a
		// 	console.log(imprints);
		// 	for (let file in imprints) {
		// 		assigned += Object.keys(imprints[file]).length;
		// 	}
		// 	console.log(signers, assigned);
		// 	if (signers !== assigned) {
		// 		ev.stopPropagation();
		// 		modalShow = true;
		// 		modalTitle = "common:message.general.warning";
		// 		modalMessage = "common:message.messages.documents.auto-stamp-warning";
		// 		ModalAction = [];
		// 		ModalAction.push(
		// 			<Button key="ok" variant="primary" onClick={(ev) => {
		// 				this.autoAddImprints(ev);
		// 				this.objMain.modalToggle(false);
		// 			}}>
		// 				<span>{t("common:general.ok")}</span>
		// 			</Button>
		// 		);

		// 		this.objMain.updateState({ modalShow, modalTitle, modalMessage, ModalAction });
		// 		// this.objMain.modalToggle(true);
		// 		return;
		// 	}
		// }

		let { pageState } = this.objMain.state;
		pageState[this.name].completed = true;
		this.objMain.updateState({ pageState: pageState });
		this.objMain.nextPageStep(ev, this.constructor);
	}

	InputPasswordForm = () => {
		let { t } = this.props;
		let {PDFFiles, selectedFile}= this.objMain.state;

		return (
			<form name="pdf-password-form">
				<Form.Row>
					<Form.Group as={Col} controlId="password" className={this.incorrectPassword}>
						<Form.Label style={{whiteSpace: 'pre-line'}}>{t('common:documents.general.pdf-password', {file_name: PDFFiles[selectedFile].name})}</Form.Label>
						<Form.Control
							name='pdf-password'
							type='password'
							placeholder={t('common:documents.general.pdf-password', {file_name: PDFFiles[selectedFile].name})}
							onChange={(ev) => {
								this.objMain.updateState({ tempPDFPassword: ev.target.value });
							}}
							required
						/>
						<Form.Control.Feedback type="invalid">
							{t('common:message.input.incorrect-password')}
						</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
			</form>
		);
	}

	modalConfirmDelete = (confirm) => {
		let { modalProps } = this.objMain.state;
		let { selectedFile, PDFFiles } = this.objMain.state;
		let PDFFile = PDFFiles[selectedFile];

		if(confirm){
			// if (!PDFFile.password) {
				this.objMain.updateState({modalConfirmDeleteShow: false})
				delete PDFFiles[selectedFile];
				selectedFile = null;
				if (Object.keys(PDFFiles).length > 0) {
					selectedFile = Object.keys(PDFFiles)[0];
				}
				this.objMain.updateState({ selectedFile });
				this.objMain.updateState({ PDFFiles });
			// }
		}else{
			this.objMain.updateState({modalConfirmDeleteShow: false})
			modalProps = {
				"show": true,
				"centered": true,
			}
			this.objMain.updateState({ modalProps });
		}
	}

	onPasswordHandler = (fnCallback, reason) => {

		let { t } = this.props;
		let { modalTitle, ModalBody, ModalAction, modalProps, onExitedModal } = this.objMain.state;
		let { selectedFile, PDFFiles, tempPDFPassword, keepPDFPassword } = this.objMain.state;
		let PDFFile = PDFFiles[selectedFile];

		modalProps = {
			"show": true,
			// "aria-labelledby": "contained-modal-title-vcenter",
			// "dialogClassName": "modal-full",
			"centered": true,
		}
		// 20210706

		// console.log(PDFFile.password);
		// console.log(tempPDFPassword, keepPDFPassword);

		onExitedModal = () => {
			// if (!PDFFile.password) {
				this.objMain.updateState({modalConfirmDeleteShow: true})
			// }
		}
		if (tempPDFPassword && reason !== 2) {
			fnCallback(tempPDFPassword);
			// this.objMain.updateState({ PDFFiles });
			this.objMain.updateState({ keepPDFPassword: tempPDFPassword });
			this.objMain.updateState({ tempPDFPassword: null });
			PDFFiles[selectedFile].password = tempPDFPassword;
			this.objMain.updateState({ PDFFiles });
			modalProps.show = false;
		} else if (PDFFile.password && reason !== 2) {
			fnCallback(PDFFile.password);
		} else {
			modalTitle = "common:documents.general.password-required";
			ModalAction = [];
			ModalBody = this.InputPasswordForm;
			ModalAction.push(
				<Button key="ok" sx={{ ml: 1 }} variant="primary" onClick={(ev) => {
					this.incorrectPassword = "was-validated";
					fnCallback(tempPDFPassword);
					this.objMain.modalToggle(false);
				}}>
					<span>{t("common:general.ok")}</span>
				</Button>
			);

			keepPDFPassword = null;

			this.objMain.updateState({ modalTitle, ModalBody, ModalAction, modalProps, onExitedModal, keepPDFPassword });
		}

		// var password = prompt('Enter the password to open this PDF file.');
		// let aa = passwordCallback("190925316195");
		// console.log(aa);
		// console.log(passwordCallback);
		// console.log(typeof passwordCallback);
	}

	loadPDFFile = async () => {
		let { PDFFiles, selectedFile, document_id } = this.objMain.state;
		let PDFFile = PDFFiles[selectedFile];

		// let { CurrentPDFPreview } = this;
		if (PDFFile) {
			if (!PDFFile.file && document_id && selectedFile) {
				let fileObject = await Service.getDocumentFile(document_id, selectedFile).catch(err => {
					//console.log(err.resonse);
				});

				// if (fileObject) {
				// 	// let content = CryptoJS.enc.Latin1.parse(fileObject);
				// 	// var fileKey = CryptoJS.MD5(content).toString();

				// 	// console.log(selectedFile);
				// 	// console.log(fileKey);
				// 	// console.log(CryptoJS.MD5(fileObject).toString());

				// } else
				if (!fileObject) {
					fileObject = "cannot-download-file";
				}
				if (PDFFiles[selectedFile]) {
					if (!PDFFile.file) {
						PDFFiles[selectedFile].file = fileObject;
						this.objMain.updateState({ PDFFiles });
					}
				}
			}
			if (PDFFile) {
				if (!PDFFile.file) {
					this.loadPDFFile();
				}
			}
		}
	}

	CurrentPDFPreview = () => {
		let { PDFFiles, selectedFile,isFileLoading } = this.objMain.state;
		let PDFFile = PDFFiles[selectedFile];

		let PDFProps = {
			showAll: true,
			file: PDFFile.file,
			password: PDFFile.password,
			objMain: this.objMain,
			isFileLoading: isFileLoading
		};
		// console.log("PDFProps", PDFProps);
		return <PDFViewer {...PDFProps} />
	}

	PDFPageLoading = () => {
		return (
			<div className="pdf-loading">
				<Loading />
			</div>
		);
	}

	PDFPageNoData = () => {
		let { t } = this.props;
		return (
			<div className="pdf-no-data">
				<span>{t("common:pdf.no-data")}</span>
			</div>
		);
	}

	attachmentExtensionAccept = () => {
		const extensions = [
			".txt",
			".doc",
			".docx",
			".ppt",
			".pptx",
			".xls",
			".xlsx",
			".gif",
			".jpg",
			".jpeg",
			".png",
			".mp4",
			".pdf",
			".psd",
			".ai",
			".zip",
			".7z",
			".gz",
			".lzh",
			".rar",
			".svg",
			".tiff",
		];
		return [...extensions, ...extensions.map(item => item.toUpperCase())].join(',');
	}

	render() {
		let { t } = this.props;
		let { PDFFiles, selectedFile, PDFLoadSuccess, isFileLoading, modalConfirmDeleteShow} = this.objMain.state;
		let objFile = null;
		let PDFPages = null;
		let totalPages = 1;
		let currentPage = 1;
		let PDFFile = PDFFiles[selectedFile];
		let { PDFPageNoData } = this;
		let {RenderDialogPreviewAttachment} = this.objMain;
		// let { CurrentPDFPreview } = this;
		if (PDFFile) {
			if (!PDFFile.file) {
				this.loadPDFFile();
			}
		}

		// console.log(selectedFile);
		if (selectedFile) {
			let PDFFile = PDFFiles[selectedFile];
			if (PDFFile) {
				objFile = PDFFile.file;
				if (PDFLoadSuccess) {
					PDFPages = this.generatePDFPages();
					// console.log(selectedFile, PDFFile.numPages);
				}
				totalPages = PDFFile.numPages;
				currentPage = PDFFile.currentPage;
				if (currentPage !== undefined) {
					var ctrlPreview = document.querySelector(".full-preview .control-preview");
					if (!ctrlPreview) {
						setTimeout(() => {
							do {
								var ctrlPreview = document.querySelector(".full-preview .control-preview");
							} while (!ctrlPreview);
							let space = ctrlPreview.offsetHeight - PDFFile.pages[currentPage].view.height;
							let scrollTop = PDFFile.pages[currentPage].view.top;
							if (space > 0) {
								scrollTop = scrollTop - (space / 2);
							}
							ctrlPreview.scrollTo(0, scrollTop);
							// console.log(currentPage);
							// console.log(ctrlPreview);
							// console.log(PDFFile.pages[currentPage].view.top);
						}, 100);
					}
				}
				// console.log(imprints);
				// console.log(PDFFile);
			}
		}

		// let DOC = new Document({ file: this.fileName });
		// console.log(DOC);
		// console.log(selectedFile, PDFPages);
		let FileList = this.renderFileList();
		let AttachmentList = this.renderAttachmentList();
		let PageInfo = "Loading..."
		if (currentPage && totalPages) {
			PageInfo = `${currentPage}/${totalPages}`;
		}
		// let PDFPages = PDFFile.pages || null;
		// let scale = 1.4;
		// console.log(objFile);
		// objFile = null;

		// let fnLoadSuccess = null;
		// if (!PDFPages) {
		// let SignersImprint = null;
		let ImprintStampController = this.renderImprintStampController();
		// console.log(this.formData.display_imprint);

		return (

			<div className={this.code + "-container"}>
				<RenderDialogPreviewAttachment />
				{this.objMain.state.showLoadingPDFLoad && Object.keys(PDFFiles).length === 0 ? <div className='showLoading'><Loading/></div>:null}
				<div className="full-preview" style={{ height: "unset", minHeight: "100%" }}>
					<div className="control-preview" ref={this.objMain.containerRef}
                        onMouseUp={this.objMain.mouseUpHandle.bind(this.objMain)}
                        onMouseMove={this.objMain.mouseMoveHandle.bind(this.objMain)}
                        onMouseDown={this.objMain.mouseDownHandle.bind(this.objMain)}>
						{/* {this.Test} */}
						{/* <PDF file="/Multiple Layout - with signed.pdf" page={1}/> */}
						<DocumentWithObserver
									showLoading={this.objMain.state.showLoadingPDFLoad}
									observerRef={this.objMain.containerRef}
									currentRangePage={currentRangePage}
									setCurrentPage={(page)=> currentRangePage.set(`${page}`)}
									file={objFile}
									onLoadSuccess={this.onDocumentLoadSuccess}
									onPassword={this.onPasswordHandler}
									options={this.pdfDocOptions}
									loading={null} noData={PDFPageNoData}
								>
									{PDFPages}
						</DocumentWithObserver>

						{/* <CurrentPDFPreview /> */}
						{/* <Page pageNumber={1} renderTextLayer={false} scale={1} /> */}
						{/* {PDFView && PDFView.viewAll} */}
						{/* {DOC} */}
						{/* <Page pageNumber={12} renderTextLayer={false} scale={scale} /> */}
						{/* </DOC> */}
						{/* {SelectedFile && SelectedFile.pages}
					</Document> */}
						{/* <PDF onDocumentComplete={this.onDocumentComplete}
						file="/compressed.tracemonkey.pdf"
						page={1} /> */}
						{/* {Object.values(PDFFiles[this.fileName])} */}
						{/* <PDF file={this.fileName} page={1} />
					<PDF file={this.fileName} page={14} /> */}
						{/* <div id="signature_1" className="hover-drag-drop-signature preview-signature">
							<div>
								<div className="preview-signature-field-valid"></div>
								<div className="dot-sign-valid"></div>
							</div>
							<div className="preview-name-signer">興野 剛</div>
							<div className="icon-remove-signature">
								<img src="https://dev-lite.paperlogic.jp/a2d339de0b44a64a69efe0f0c5737415.png" alt="" />
							</div>
						</div> */}

					</div>

					<div className="control-container">
						<div className="view-size-controller">
							<div className="btn size-decrease" onClick={this.decreasePreviewSize}>
								<FontAwesomeIcon icon={faMinus} />
							</div>
							<div className="btn size-increase" onClick={this.increasePreviewSize}>
								<FontAwesomeIcon icon={faPlus} />
							</div>
						</div>

						<div className="page-index-info">
							<StateFragment state={currentRangePage}>{s => {
								// console.log(';diksjfolgsndối', s.get());
								return  <span className="info-label">{s.get() && PDFFile? <>{s.get()} / {PDFFile.numPages}</> : 'Loading...'}</span>
							}}</StateFragment>
						</div>

						{ImprintStampController}

						<Form id={"form_" + this.name} onSubmit={this.formSubmitValidation}>
							<div className="page-action-bottom">
								<div className="wrapper-button-cancel">
									<MuiButton
										variant="contained"
										color="info"
										size='large'
										startIcon={<ChevronLeftIcon />}
										onClick={(ev) => this.objMain.prevPageStep(ev, this.constructor)}>
										<span className="btn-label">{t('common:documents.general.back')}</span>
									</MuiButton>
								</div>
								<div className="wrapper-button-submit">
									<LoadingButton
										loading={isFileLoading}
										variant="contained"
										type="submit"
										size='large'
										endIcon={<ChevronRightIcon />}
									>
										<span className="btn-label">{t('common:documents.general.next')}</span>
									</LoadingButton>
								</div>
							</div>
						</Form>

					</div>

				</div>

				<div className="file-panel upload">


					<div className={"file-drop-zone" + (Object.keys(PDFFiles).length > 0 ? "" : " nofile")}>

						<label htmlFor="pdf-upload"
							className="file-dropbox"
							onDragOver={this.handleDragOver}
							onDrop={this.handleDropFile}
							onDragLeave={this.handleDragLeave}
							style={{ backgroundImage: `url(${PDFBGIcon})` }}>
							<input type="file" name="pdf-upload" accept=".pdf,image/*" id="pdf-upload" multiple onChange={this.renderPDFPages} />
						</label>
						<label className="info">{t('common:document.upload-pdf')}</label>

					</div>

					<div className="file-upload-list">
						{FileList}
					</div>

					<label htmlFor="attachment-upload"
						className="attachment-upload"
						onDragOver={this.handleDragOver}
						onDrop={this.handleDropFile}
						onDragLeave={this.handleDragLeave}>
							<div className='attachment-upload-box'>
								<img src={ClipIcon} alt="" />
								<span>{t('common:document.upload-attachment')}</span>
							</div>

						<input
							hidden
							type="file"
							name="attachment-upload"
							accept={this.attachmentExtensionAccept()}
							id="attachment-upload"
							multiple
							onChange={this.uploadAttachment}
						/>
					</label>
					<div className="file-attachment-list">
						{AttachmentList}
					</div>
				</div>

				<Modal show={modalConfirmDeleteShow} animation={false} onHide={(ev) => { this.modalConfirmDelete(false) }} centered={true} >
					<Modal.Header closeButton>
					<Modal.Title>{t('common:document.title.pdf-password-confirm-delete')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>{t('common:document.message.pdf-password-confirm-delete')}</Modal.Body>
					<Modal.Footer>
					<Button variant="secondary" onClick={(ev) => { this.modalConfirmDelete(false) }}>{t('common:auth.general.cancel')}</Button>
					<Button variant="primary" onClick={(ev) => { this.modalConfirmDelete(true) }}>{t('common:auth.general.ok')}</Button>
					</Modal.Footer>

				</Modal>

			</div >

		);

	}

}
